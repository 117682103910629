import React, { Component } from "react";
import { navigate, Link } from "gatsby";

import * as routes from "constants/routes";
import { doSignInWithEmailAndPassword } from "api/auth";

import SignInWithGoogle from "./OAuth/Google";
import SignInWithGithub from "./OAuth/Github";
import { Button, AuthContainer } from "./OAuth/styles";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  showForm: false
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if (this.props.location.state && typeof window != "undefined") {
      localStorage.setItem("previousProjectId", this.props.location.state.projectId);
      localStorage.setItem("previousProjectTitle", this.props.location.state.projectTitle);
    }
  }

  onSubmit = event => {
    const { email, password } = this.state;

    doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        navigate(routes.HOME);
      })
      .catch(error => {
        this.setState(updateByPropertyName("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { email, password, error, showForm } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <AuthContainer>
        <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">Login</h1> {/* <SignInWithTwitter /> */}
        <br/>

        <SignInWithGoogle />
        <SignInWithGithub />
        <Button onClick={() => this.setState({ showForm: true })}>Email</Button>
        {showForm && (
          <div>
            <form onSubmit={this.onSubmit}>
              <input
                className="input"
                value={email}
                onChange={event =>
                  this.setState(
                    updateByPropertyName("email", event.target.value)
                  )
                }
                type="text"
                placeholder="Email Address"
              />
              <input
                className="input"
                value={password}
                onChange={event =>
                  this.setState(
                    updateByPropertyName("password", event.target.value)
                  )
                }
                type="password"
                placeholder="Password"
              />
              <Button disabled={isInvalid} type="submit">
                Login
              </Button>

              {error && <p>{error.message}</p>}
            </form>
            <br />
          </div>
        )}
        <small>
          By signing in to Enlight, you agree to our{" "}
          <Link to="/terms">Terms of Service.</Link>
        </small>{" "}
      </AuthContainer>
    );
  }
}

export default SignInForm;
